<template>
  <zee-container :classname="'ml-5'" :showIcon="'true'" :title="'select model'">
    <zee-toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <div class="zee-model-select">
      <div class="d-flex align-items-center">
        <zee-button
          :isDisable="isDisable"
          @onClick="handleInput"
          style="width: 300px"
          :title="`MMM model`"
        />
        <!-- <h3 class="mr-5">MMM model</h3>
        <button
          @input="handleInput"
          value="One"
          v-model="selectedModel"
          type="radio"
          name="abc"
        /> -->
      </div>
      <div class="d-flex align-items-center mt-2">
        <zee-button
          @onClick="handleInput"
          style="width: 300px"
          :title="`Independent Scheduler`"
        />

        <!-- <h3 class="mr-5">Independent Scheduler</h3>
        <input
          @input="handleInput"
          value="Two"
          v-model="selectedModel"
          type="radio"
          name="abc"
        /> -->
      </div>
    </div>
  </zee-container>
</template>

<script>
import { Container, Button } from "../components";
import Toast from "@/components/Toast/Toast.vue";

export default {
  name: "Model Select",
  components: {
    "zee-container": Container,
    "zee-button": Button,
    "zee-toast": Toast,
  },
  data() {
    return {
      selectedModel: "",
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      isDisable: false,
    };
  },
  methods: {
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    handleInput(e) {
      let value = e.target.innerText.trim();
      switch (value) {
        case "MMM model":
          console.log("mmm");
          this.isDisable = true;
          this.toastData = {
            show: true,
            type: "edit",
            message: "Model is being built, please select Independent Scheduler",
          };
          break;
        case "Independent Scheduler":
          this.$router.push("/solutions/zeetv/program-launch");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.zee-model-select {
  display: flex;
  justify-content: space-around;
}
input {
  width: 20px;
  height: 20px;
}
</style>
